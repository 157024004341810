import _URL from "./lib/URL";
import _URLSearchParams from "./lib/URLSearchParams";
var exports = {};
const URL = _URL;
const URLSearchParams = _URLSearchParams;
exports.URL = URL;
exports.URLSearchParams = URLSearchParams;
export default exports;
const _URL2 = exports.URL,
      _URLSearchParams2 = exports.URLSearchParams;
export { _URL2 as URL, _URLSearchParams2 as URLSearchParams };